let lastScrollTop = window.pageYOffset
let animateCardTimeout
let animateCardState = 0
let animateCardResetTimeout
const image = document.querySelector('.bonuses__image-wrapper')

const addClassOnScroll = () => {
  return setTimeout(() => {
    if (image) {
      image.classList.remove('move-down')
      image.classList.add('move-up')
    }
  }, 0)
}

const removeClassOnScroll = () => {
  return setTimeout(() => {
    if (image) {
      image.classList.remove('move-up')
      image.classList.add('move-down')
    }
  }, 0)
}

const changeClassOnScroll = () => {
  const scrollTop = window.pageYOffset
  const delta = lastScrollTop - scrollTop
  lastScrollTop = scrollTop
  if (delta > 0) {
    if (animateCardState !== 1) {
      animateCardTimeout && clearTimeout(animateCardTimeout)
      animateCardTimeout = addClassOnScroll()
      animateCardState = 1
    }
  } else if (delta < 0) {
    if (animateCardState !== -1) {
      animateCardTimeout && clearTimeout(animateCardTimeout)
      animateCardTimeout = removeClassOnScroll()
      animateCardState = -1
    }
  }
  if (delta !== 0) {
    animateCardResetTimeout && clearTimeout(animateCardResetTimeout)
    animateCardResetTimeout = setTimeout(() => {
      animateCardState = 0
      if (image) {
        image.classList.remove('move-up')
        image.classList.remove('move-down')
      }
    }, 300)
  }
  requestAnimationFrame(changeClassOnScroll)
}
changeClassOnScroll()
